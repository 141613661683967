import request, { gql } from 'graphql-request';
import { PERIOD_IN_SECONDS } from '../constants/period';
import { FuturesMarketAsset, } from '../types';
import { getPerpsV3SubgraphUrl, orderSettledToTrade } from '../utils';
export const queryPerpsV3Accounts = async (chainId, walletAddress) => {
    const response = await request(getPerpsV3SubgraphUrl(chainId), gql `
			query GetAccounts($owner: String) {
				accounts(where: { owner_contains: $owner }) {
					id
					accountId
					owner
				}
			}
		`, {
        owner: walletAddress,
    });
    return response.accounts;
};
export const querySettlementStrategies = async (chainId) => {
    const response = await request(getPerpsV3SubgraphUrl(chainId), gql `
			query GetSettlementStrategies {
				settlementStrategies(orderBy: "marketId", orderDirection: "desc") {
					id
					marketId
					strategyId
					strategyType
					settlementDelay
					settlementWindowDuration
					settlementReward
				}
			}
		`);
    return response.settlementStrategies;
};
const OrderSettledFragment = `
	fragment OrderSettledFragment on OrderSettled {
		id
		accountId
		marketId
		fillPrice
		timestamp
		sizeDelta
		totalFees
		interestCharged
		accruedFunding
		pnl
		account {
			owner
		}
		txnHash
		orderCommitted {
			txnHash
		}
		position {
			id
		}
	}
`;
export const queryPerpsV3SettledOrders = async (chainId, filter, { pageSize = 100, skip = 0 } = {}) => {
    // Build the query conditionally based on provided filter fields
    const queryConditions = [];
    if (filter.accountId) {
        queryConditions.push('accountId: $accountId');
    }
    if (filter.marketId) {
        queryConditions.push('marketId: $marketId');
    }
    if (filter.positionId) {
        queryConditions.push('position: $position');
    }
    const query = queryConditions.length ? `{ ${queryConditions.join(', ')} }` : '';
    const response = await request(getPerpsV3SubgraphUrl(chainId), gql `
			${OrderSettledFragment}
			query GetAccountTrades($accountId: String, $marketId: String, $position: String, $pageSize: Int, $skip: Int) {
				orderSettleds(first: $pageSize, skip: $skip, where: ${query}, orderBy: "timestamp", orderDirection: "desc") {
					...OrderSettledFragment
				}
			}
		`, {
        accountId: String(filter.accountId),
        marketId: String(filter.marketId),
        position: String(filter.positionId),
        pageSize,
        skip,
    });
    return orderSettledToTrade(chainId, response.orderSettleds);
};
export const queryAllPerpsV3SettledOrders = async (chainId, marketId, { minTimestamp, maxTimestamp, pageSize = 100, skip = 0 }) => {
    const response = await request(getPerpsV3SubgraphUrl(chainId), gql `
			${OrderSettledFragment}
			query GetAllTrades(
				$marketId: String
				$minTimestamp: BigInt
				$maxTimestamp: BigInt
				$pageSize: Int
				$skip: Int
			) {
				orderSettleds(
					orderBy: "timestamp"
					orderDirection: "desc"
					first: $pageSize
					skip: $skip
					where: { marketId: $marketId, timestamp_gt: $minTimestamp, timestamp_lt: $maxTimestamp }
				) {
					...OrderSettledFragment
				}
			}
		`, {
        minTimestamp,
        maxTimestamp,
        pageSize,
        skip,
        marketId: String(marketId),
    });
    return orderSettledToTrade(chainId, response.orderSettleds);
};
const PositionLiquidationFragment = `
	fragment PositionLiquidationFragment on PositionLiquidation {
		id
		accountId
		accountOwner
		timestamp
		marketId
		amount
		notionalAmount
		estimatedPrice
	}
`;
export const queryPerpsV3PositionLiquidations = async (chainId, accountId, marketId, { pageSize = 100, skip = 0 } = {}) => {
    const query = marketId
        ? '{ accountId: $accountId, marketId: $marketId }'
        : '{ accountId: $accountId }';
    const response = await request(getPerpsV3SubgraphUrl(chainId), gql `
			${PositionLiquidationFragment}
			query GetAccountLiquidations($accountId: String, $marketId: String, $pageSize: Int, $skip: Int) {
				positionLiquidations(first: $pageSize, skip: $skip, where: ${query}, orderBy: "timestamp", orderDirection: "desc") {
					...PositionLiquidationFragment
				}
			}
		`, {
        accountId: String(accountId),
        marketId: String(marketId),
        pageSize,
        skip,
    });
    return response.positionLiquidations;
};
export const queryAllPerpsV3PositionLiquidations = async (chainId, marketId, { minTimestamp, maxTimestamp, pageSize = 100, skip = 0 }) => {
    const response = await request(getPerpsV3SubgraphUrl(chainId), gql `
			${PositionLiquidationFragment}
			query GetAllLiquidations(
				$marketId: String
				$minTimestamp: BigInt
				$maxTimestamp: BigInt
				$pageSize: Int
				$skip: Int
			) {
				positionLiquidations(
					orderBy: "timestamp"
					orderDirection: "desc"
					first: $pageSize
					skip: $skip
					where: { marketId: $marketId, timestamp_gt: $minTimestamp, timestamp_lt: $maxTimestamp }
				) {
					...PositionLiquidationFragment
				}
			}
		`, {
        minTimestamp,
        maxTimestamp,
        marketId: String(marketId),
        pageSize,
        skip,
    });
    return response.positionLiquidations;
};
export const queryFundingRateHistory = async (chainId, marketAsset, minTimestamp, period = 'Hourly') => {
    const response = await request(getPerpsV3SubgraphUrl(chainId), gql `
			query fundingRateUpdate(
				$marketAsset: Bytes!
				$minTimestamp: BigInt!
				$period: FundingRatePeriodType!
			) {
				fundingRatePeriods(
					where: { marketSymbol: $marketAsset, timestamp_gt: $minTimestamp, period: $period }
					first: 2000
				) {
					timestamp
					fundingRate
				}
			}
		`, {
        marketAsset: marketAsset === FuturesMarketAsset.sBTC || marketAsset === FuturesMarketAsset.sETH
            ? marketAsset.slice(1)
            : marketAsset,
        minTimestamp,
        period,
    });
    return response.fundingRatePeriods.map((x) => ({
        timestamp: Number(x.timestamp) * 1000,
        fundingRate: Number(x.fundingRate),
    }));
};
export const queryPerpsV3Positions = async (chainId, account, status, { pageSize = 100, skip = 0 } = {}) => {
    const isOpen = status === 'open' ? 'isOpen: true' : status === 'closed' ? 'isOpen: false' : '';
    const response = await request(getPerpsV3SubgraphUrl(chainId), gql `
			query GetAccountPositions($account: String!, $pageSize: Int, $skip: Int) {
				perpsV3Positions(
					where: {
						accountId: $account
						${isOpen}
					}
					orderBy: "timestamp"
					orderDirection: "desc",
					first: $pageSize,
					skip: $skip
				) {
					id
					openTimestamp
					closeTimestamp
					timestamp
					marketId
					marketSymbol
					accountId
					account {
						owner
					}
					isOpen
					isLiquidated
					totalTrades
					totalVolume
					realizedPnl
					feesPaid
					netFunding
					pnlWithFeesPaid
					entryPrice
					avgEntryPrice
					lastPrice
					exitPrice
					totalReducedNotional
					interestCharged
					size
					trades (orderBy: timestamp, orderDirection: asc) {
						sizeDelta
					}
				}
			}
		`, {
        account,
        pageSize,
        skip,
    });
    return response.perpsV3Positions;
};
export const queryDelegatesForAccount = async (chainId, walletAddress) => {
    const response = await request(getPerpsV3SubgraphUrl(chainId), gql `
			query Delegates($walletAddress: String!) {
				delegatedAccounts(first: 1000, where: { caller: $walletAddress }) {
					delegate
				}
			}
		`, { walletAddress });
    const delegates = (await response.delegatedAccounts.map((entry) => ({
        delegate: entry.delegate,
    })));
    return delegates;
};
export const querySubAccountsForAccount = async (chainId, walletAddress) => {
    const response = await request(getPerpsV3SubgraphUrl(chainId), gql `
			query SubAccounts($walletAddress: String!) {
				delegatedAccounts(first: 1000, where: { delegate: $walletAddress }) {
					caller
				}
			}
		`, { walletAddress });
    const subAccounts = (await response.delegatedAccounts.map((entry) => ({
        caller: entry.caller,
    })));
    return subAccounts;
};
export const queryPerpsV3DailyVolume = async (chainId, minTimestamp, period = PERIOD_IN_SECONDS.ONE_HOUR) => {
    const response = await request(getPerpsV3SubgraphUrl(chainId), gql `
			query GetPerpsV3AggregateStats($minTimestamp: BigInt!, $period: BigInt!) {
				perpsV3AggregateStats(where: { timestamp_gte: $minTimestamp, period: $period }) {
					marketSymbol
					period
					trades
					volume
				}
			}
		`, {
        minTimestamp,
        period,
    });
    return response.perpsV3AggregateStats;
};
export const queryCollateralChanges = async (chainId, accountId) => {
    return request(getPerpsV3SubgraphUrl(chainId), gql `
			query CollateralChanges($accountId: String!) {
				collateralChanges(
					where: { accountId: $accountId }
					orderBy: "timestamp"
					orderDirection: "desc"
				) {
					synthId
					accountId
					txHash
					timestamp
					amountDelta
					sender
				}
			}
		`, { accountId });
};
export const getConditionalOrders = () => { };
export const queryOrderSettleds = (chainId, accountId, { pageSize = 100, skip = 0 } = {}) => {
    return request(getPerpsV3SubgraphUrl(chainId), gql `
			query OrderSettleds($accountId: String!, $pageSize: Int, $skip: Int) {
				orderSettleds(
					where: { accountId: $accountId }
					orderBy: "timestamp"
					orderDirection: "desc"
					first: $pageSize
					skip: $skip
				) {
					id
					timestamp
					marketId
					fillPrice
					accruedFunding
					sizeDelta
					newSize
					totalFees
					referralFees
					collectedFees
					settlementReward
					trackingCode
					settler
					pnl
					orderCommitted {
						txnHash
					}
					txnHash
				}
			}
		`, { accountId, pageSize, skip });
};
export const queryPnlSnapshots = async (chainId, accountId) => {
    const res = (await request(getPerpsV3SubgraphUrl(chainId), gql `
			query PnlSnapshots($accountId: String!) {
				pnlSnapshots(
					where: { accountId: $accountId }
					orderBy: "timestamp"
					orderDirection: "desc"
				) {
					id
					timestamp
					pnl
					accountId
				}
			}
		`, { accountId }));
    return res.pnlSnapshots;
};
